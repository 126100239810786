<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: cardAllPondsTable.vue
Description:this file is the child Component of the PondInfoTab where data is shown in the table
-->
<template>
  <er-data-tables
    class="card-all-ponds-table"
    :key="$i18n.locale"
    :tableData="tablePonds"
    :columns="columns"
    :elTableProps="tableProps"
    :action-column="false"
    :sortMethodForColumns="sortMethods()"
    :selectedColumnHeaders="selectedHeaders"
    :selectedColumnHeadersSortable="true"
    uniqueId="pond-info-table"
    type="white-header-table"
    layout="table"
    v-loading="loading || tableLoading"
    element-loading-background="white"
    :searchOnTableData="pondSearch"
    ref="pondInfotable"
    @row-click="handleRowClick"
  >
    <template v-slot:title_header="{ header }">
      <span>{{ header.label }}</span>
    </template>
    <template v-slot:title="{ row }">
      <er-text-tag
        :text-to-scroll="`${upm__trimSpacesInStr(row.data.title)}`"
        :threshold-characters="10"
      >
      <span>{{ upm__trimSpacesInStr(row.data.title) }}</span>
        <!-- <er-highlight-search-component
          :highlightStrings="row.data.highlightStrings"
          :individualRowData="row.data"
          keyName="title"
          :withoutHighlightStrings="row.data.title"
        /> -->
      </er-text-tag>

      <!-- <template v-if="isKeyExists(row.data.highlightStrings, 'title')">
        <span style="display: flex">
          <span
            v-for="(substr, index) in row.data.highlightStrings.title[0]"
            :key="index"
            :class="{ highlight: substr.highlight }"
          >
            {{ substr.value }}
          </span>
        </span>
      </template>
      <template v-else>
        {{ row.data.title }}
      </template> -->
    </template>
    <template v-slot:days_of_cultivation_header="{ header }">
      <er-text-tag
        :text-to-scroll="`${header.label} ${header.sub_label}`"
        :threshold-characters="16"
      >
        <span>{{ header.label }}</span>
        <span v-if="header.sub_label.length !== 0" class="header-sub-label"
          >({{ header.sub_label }})</span
        >
      </er-text-tag>
    </template>
    <template v-slot:days_of_cultivation="{ row }">
      {{ row.data.days_of_cultivation }}
    </template>
    <template v-slot:abw_header="{ header }">
      <er-text-tag
        :text-to-scroll="`${header.label} ${header.sub_label}`"
        :threshold-characters="16"
      >
        <span>{{ header.label }}</span>
        <span class="header-sub-label">({{ header.sub_label }})</span>
        <span>
          <i class="el-icon-plus" @click="abwnested"></i>
        </span>
      </er-text-tag>
    </template>
    <template v-slot:abw_NestedColumn>
      <template v-for="index in abwShowintialCol">
        <el-table-column
          :key="index"
          :min-width="55 + 12 * abwShowColOffset"
          align="left"
        >
          <template slot-scope="{ row }">
            <el-col
              tag="p"
              :title="
                ftm__digitPrecision(
                  getPondIdToLatestNABWValues[row._id][
                    index + abwShowColOffset - 1
                  ].abw,
                  2 || 0
                ) === 0
                  ? ''
                  : getFormattedDate(
                      getPondIdToLatestNABWValues[row._id][
                        index + abwShowColOffset - 1
                      ].date
                    )
              "
              :style="`text-align:left`"
              :class="`abw-${index + abwShowColOffset}`"
            >
              {{
                ftm__digitPrecision(
                  getPondIdToLatestNABWValues[row._id][
                    index + abwShowColOffset - 1
                  ].abw,
                  1
                ) >= 0.1
                  ? ftm__digitPrecision(
                      getPondIdToLatestNABWValues[row._id][
                        index + abwShowColOffset - 1
                      ].abw,
                      2
                    )
                  : "--"
              }}
            </el-col>
          </template>
        </el-table-column>
      </template>
    </template>
    <template v-slot:awg_header="{ header }">
      <er-text-tag
        :text-to-scroll="`${header.label} ${header.sub_label}`"
        :threshold-characters="16"
      >
        <span>{{ header.label }}</span>
        <span class="header-sub-label">({{ header.sub_label }})</span>
        <span>
          <i class="el-icon-plus" @click="awgnested"></i>
        </span>
      </er-text-tag>
    </template>
    <template v-slot:awg_NestedColumn>
      <template v-for="index in awgShowintialCol">
        <el-table-column
          :key="index"
          :min-width="55 + 12 * awgShowColOffset"
          align="left"
        >
          <template slot-scope="{ row }">
            <el-col
              tag="p"
              :title="
                ftm__digitPrecision(
                  getPondIdToLatestNABWValues[row._id][
                    index + awgShowColOffset - 1
                  ].awg,
                  2 || 0
                ) === 0
                  ? ''
                  : getFormattedDate(
                      getPondIdToLatestNABWValues[row._id][
                        index + awgShowColOffset - 1
                      ].date
                    )
              "
              :style="`text-align:left`"
              :class="`abw-${index + awgShowColOffset}`"
            >
              {{
                ftm__digitPrecision(
                  getPondIdToLatestNABWValues[row._id][
                    index + awgShowColOffset - 1
                  ].awg,
                  1
                ) >= 0.1
                  ? ftm__digitPrecision(
                      getPondIdToLatestNABWValues[row._id][
                        index + awgShowColOffset - 1
                      ].awg,
                      1
                    )
                  : "--"
              }}
            </el-col>
          </template>
        </el-table-column>
      </template>
    </template>
    <template v-slot:biomass_header="{ header }">
      <er-text-tag
        :text-to-scroll="`${header.label} ${header.sub_label}`"
        :threshold-characters="16"
      >
        <span>{{ header.label }}</span>
        <span class="header-sub-label">({{ header.sub_label }})</span>
      </er-text-tag>
    </template>
    <template v-slot:biomass="{ row }">
      <el-row class="cell-container">
        <p
          v-upm__units.digitPrecision="getBiomassAndUnits(row.data.biomass)"
        ></p>
      </el-row>
    </template>
    <template v-slot:do_header="{ header }">
      <er-text-tag
        :text-to-scroll="`${header.label} ${header.sub_label}`"
        :threshold-characters="16"
      >
        <span>{{ header.label }}</span>
        <span class="header-sub-label">({{ header.sub_label }})</span>
      </er-text-tag>
    </template>
    <template v-slot:do="{ row }">
      <el-popover
        v-if="getPondIdToLatestNDOTempValues[row.data._id][0].do"
        placement="top-start"
        width="200"
        trigger="hover"
        popper-class="do-pop-container"
        @after-enter="onHover(getPondIdToLatestNDOTempValues[row.data._id])"
      >
        <div>
          <el-row
            class="do-last-date"
            v-loading="lastDoDateLoading"
            element-loading-spinner="el-icon-loading"
          >
            {{ $t("Comn_time") }}:
            <span>{{ ftm__formatEmpty(getDoDateSince) }}</span>
          </el-row>
          <p class="do-hours-label">{{ $t("Comn_last") }} 3 {{ $t("Hrs") }}</p>
          <div>
            <img
              v-if="getDoInDateRange.length > 0"
              src="@/assets/pond-info/down-arrow.svg"
              atl="down arrow"
              style="float: right; margin-bottom: 2px"
            />
            <div class="row__bar-container">
              <!-- <high-charts :options="getChartOptions" ref="highcharts" constructor-type="stockChart"></high-charts> -->
              <el-row
                class="bar"
                v-loading="doDateRangeLoading"
                element-loading-spinner="el-icon-loading"
              >
                <template v-if="getDoInDateRange.length > 0">
                  <div
                    v-for="(item, index) in getDoInDateRange"
                    :key="`${item.color}${index}`"
                    class="bar__filler"
                    :style="
                      getBarStyling(index, getDoInDateRange.length, item.color)
                    "
                  ></div>
                </template>
                <el-tag v-else>{{ $t("Comn_no_data") }}</el-tag>

                <!-- <div class="bar__filler" :style="`width:${100}px; background: green`"></div> -->
              </el-row>
            </div>
          </div>
        </div>
        <div style="display: flex" slot="reference">
          <template
            v-for="(device, index) in getPondIdToLatestNDOTempValues[
              row.data._id
            ]"
          >
            <el-col tag="p" :key="index" :style="generateColorForDo(device)">{{
              ftm__formatEmpty(ftm__digitPrecision(device.do, 1))
            }}</el-col>
          </template>
        </div>
      </el-popover>
      <div style="display: flex" v-else>
        <template
          v-for="(device, index) in getPondIdToLatestNDOTempValues[
            row.data._id
          ]"
        >
          <el-col tag="p" :key="index" :style="generateColorForDo(device)">{{
            ftm__formatEmpty(ftm__digitPrecision(device.do, 1))
          }}</el-col>
        </template>
      </div>
    </template>
    <template v-slot:temp_header="{ header }">
      <er-text-tag
        :text-to-scroll="`${header.label} ${header.sub_label}`"
        :threshold-characters="16"
      >
        <span>{{ header.label }}</span>
        <span class="header-sub-label">({{ header.sub_label }})</span>
      </er-text-tag>
    </template>
    <template v-slot:temp="{ row }">
      <el-popover
        v-if="getPondIdToLatestNDOTempValues[row.data._id][0].temp"
        placement="top-start"
        width="200"
        trigger="hover"
        popper-class="do-pop-container"
        @after-enter="onTempHover(getPondIdToLatestNDOTempValues[row.data._id])"
      >
        <div>
          <el-row
            class="do-last-date"
            v-loading="lastDoDateLoading"
            element-loading-spinner="el-icon-loading"
          >
            {{ $t("Comn_time") }}:
            <span>{{ ftm__formatEmpty(getDoDateSince) }}</span>
          </el-row>
          <p class="do-hours-label">{{ $t("Comn_last") }} 3 {{ $t("Hrs") }}</p>
          <div>
            <img
              v-if="getDoInDateRange.length > 0"
              src="@/assets/pond-info/down-arrow.svg"
              atl="down arrow"
              style="float: right; margin-bottom: 2px"
            />
            <div class="row__bar-container">
              <!-- <high-charts :options="getChartOptions" ref="highcharts" constructor-type="stockChart"></high-charts> -->
              <el-row
                class="bar"
                v-loading="doDateRangeLoading"
                element-loading-spinner="el-icon-loading"
              >
                <template v-if="getDoInDateRange.length > 0">
                  <div
                    v-for="(item, index) in getDoInDateRange"
                    :key="`${item.color}${index}`"
                    class="bar__filler"
                    :style="
                      getBarStyling(index, getDoInDateRange.length, item.color)
                    "
                  ></div>
                </template>
                <el-tag v-else>{{ $t("Comn_no_data") }}</el-tag>

                <!-- <div class="bar__filler" :style="`width:${100}px; background: green`"></div> -->
              </el-row>
            </div>
          </div>
        </div>
        <div style="display: flex" slot="reference">
          <template
            v-for="(device, index) in getPondIdToLatestNDOTempValues[
              row.data._id
            ]"
          >
            <el-col tag="p" :key="index" :style="generateColorForTemp(device)">
              {{ ftm__formatEmpty(ftm__digitPrecision(device.temp, 1)) }}
            </el-col>
          </template>
        </div>
      </el-popover>
      <div style="display: flex" v-else>
        <template
          v-for="(device, index) in getPondIdToLatestNDOTempValues[
            row.data._id
          ]"
        >
          <el-col tag="p" :key="index" :style="generateColorForDo(device)">{{
            ftm__formatEmpty(ftm__digitPrecision(device.do, 1))
          }}</el-col>
        </template>
      </div>
    </template>
    <template v-slot:feed_header="{ header }">
      <er-text-tag
        :text-to-scroll="`${header.label} ${header.sub_label}`"
        :threshold-characters="16"
        :loading="loading"
      >
        <span>{{ header.label }}</span>
        <span class="header-sub-label">({{ header.sub_label }})</span>
      </er-text-tag>
    </template>
    <template v-slot:feed="{ row }">
      <span>{{ftm__digitPrecision(row.data.feed, 1) >= 0 ? accountFormattedFloatValue(ftm__digitPrecision(row.data.feed, 1), userCountryCode) : '--'}}</span>
    </template>
    <template v-slot:dispensed_feed_header="{ header }">
      <er-text-tag
        :text-to-scroll="`${header.label} ${header.sub_label}`"
        :threshold-characters="16"
        :loading="loading"
      >
        <span>{{ header.label }}</span>
        <span class="header-sub-label">({{ header.sub_label }})</span>
      </er-text-tag>
    </template>
    <template v-slot:dispensed_feed="{ row }">
      <span>{{ftm__digitPrecision(row.data.dispensed_feed, 1) >= 0 ? accountFormattedFloatValue(ftm__digitPrecision(row.data.dispensed_feed, 1), userCountryCode) : '--'}}</span>
    </template>
    <template v-slot:total_feed_header="{ header }">
      <er-text-tag
        :text-to-scroll="`${header.label} ${header.sub_label}`"
        :threshold-characters="16"
        :loading="loading"
      >
        <span>{{ header.label }}</span>
        <span class="header-sub-label">({{ header.sub_label }})</span>
      </er-text-tag>
    </template>
    <template v-slot:total_feed="{ row }">
      <el-popover
        placement="top"
        trigger="hover"
        popper-class="total-feed-pop-container"
      >
        <div style="display: flex; flex-direction: column">
          <div style="display: flex">
            <p style="min-width: 90px">{{ $t("Comn_manual") }}</p>
            <p>
              {{
                ftm__digitPrecision(row.data.total_dispensed_feed_for_manual, 1) >= 0 ? accountFormattedFloatValue(ftm__digitPrecision(row.data.total_dispensed_feed_for_manual, 1), userCountryCode) : 0
              }}
              ({{
                ftm__digitPrecision(row.data.dispensed_feed_for_manual, 1) >= 0 ? accountFormattedFloatValue(ftm__digitPrecision(row.data.dispensed_feed_for_manual, 1), userCountryCode) : 0
              }})
              <!-- {{
                updateFeedBasedOnUnits(row.data.total_dispensed_feed_for_manual)
              }}
              ({{ updateFeedBasedOnUnits(row.data.dispensed_feed_for_manual) }}) -->
            </p>
          </div>
          <div style="display: flex">
            <p style="min-width: 90px">{{ $t("Comn_pond_mother") }}</p>
            <p>
              {{
                ftm__digitPrecision(row.data.total_dispensed_feed_for_pond_mother, 1) >= 0 ? accountFormattedFloatValue(ftm__digitPrecision(row.data.total_dispensed_feed_for_pond_mother, 1), userCountryCode) : 0
              }}
              ({{
                ftm__digitPrecision(row.data.dispensed_feed_for_pond_mother, 1) >= 0 ? accountFormattedFloatValue(ftm__digitPrecision(row.data.dispensed_feed_for_pond_mother, 1), userCountryCode) : 0
              }})
              <!-- {{
                updateFeedBasedOnUnits(
                  row.data.total_dispensed_feed_for_pond_mother
                )
              }}
              ({{
                updateFeedBasedOnUnits(row.data.dispensed_feed_for_pond_mother)
              }}) -->
            </p>
          </div>
        </div>
        <div style="display: flex" slot="reference">
          <el-row type="flex">
            <!-- <p
              v-upm__units.digitPrecision="
                getTotalFeed(row.data.total_dispensed_feed)
              "
            ></p> -->
              {{ftm__digitPrecision(row.data.total_dispensed_feed, 1) >= 0 ? accountFormattedFloatValue(ftm__digitPrecision(row.data.total_dispensed_feed, 1), userCountryCode) : '--'}}
              <!-- &nbsp; (<span
              :style="generateColorForNetFeed(row.data)"
              class="second-value">{{ftm__digitPrecision(row.data.dispensed_feed, 1) >= 0 ? accountFormattedFloatValue(ftm__digitPrecision(row.data.dispensed_feed, 1), userCountryCode) : '--'}}</span>) -->
            <!-- &nbsp; (<span
              :style="generateColorForNetFeed(row.data)"
              class="second-value"
              v-upm__units.digitPrecision="
                getDispensedFeed(row.data.dispensed_feed)
              "
            >
            </span
            >) -->
          </el-row>
        </div>
      </el-popover>
    </template>
    <template v-slot:feed_per_area_header="{ header }">
      <er-text-tag
        :text-to-scroll="`${header.label} ${header.sub_label}`"
        :threshold-characters="16"
        :loading="loading"
      >
        <span>{{ header.label }}</span>
        <!-- <span class="header-sub-label">({{ header.sub_label }})</span> -->
      </er-text-tag>
    </template>
    <template v-slot:feed_per_area="{ row }">
      <p
        v-upm__units.digitPrecision="
          getFeedPerAreaConfig(
            [row.data.total_dispensed_feed, row.data.size],
            ['kg', row.data.area_units]
          )
        "
      ></p>
    </template>
    <template v-slot:mode_header="{ header }">
      <span>{{ header.label }}</span>
    </template>
    <template v-slot:mode="{ row }">
      <span :style="generateColorForMode(row.data)">
        {{ mode(row.data) }}
      </span>
    </template>
    <template v-slot:survival_percentage_header="{ header }">
      <span>{{ header.label }}</span>
    </template>
    <template v-slot:survival_percentage="{ row }">
      <div>
        <span class="cell-container column">
          <p
            :style="`color:${getProgressBarColor(
              row.data.survival_percentage
            )}`"
          >
            {{ ftm__digitPrecision(row.data.survival_percentage, 1) }}%
          </p>
        </span>
      </div>
    </template>
    <template v-slot:post_larva_stocked="{ row }">
      <span>{{
        ftm__digitPrecision(
          ftm__formatEmpty(
            ftm__formatNumber(row.data.post_larva_stocked, userCountryCode)
          ),
          0
        )
      }}</span>
    </template>
    <template v-slot:fcr="{ row }">
      <span>{{ accountFormattedFloatValue(ftm__digitPrecision(row.data.fcr, 2), userCountryCode) }}</span>
    </template>
    <template v-slot:size_header="{ header }">
      <er-text-tag
        :text-to-scroll="`${header.label} ${header.sub_label}`"
        :threshold-characters="16"
      >
        <span>{{ header.label }}</span>
        <span class="header-sub-label">({{ header.sub_label }})</span>
      </er-text-tag>
    </template>
    <template v-slot:size="{ row }">
      <p
        v-upm__units.digitPrecision="
          getPondSize(row.data.size, row.data.area_units)
        "
      ></p
    ></template>
    <template v-slot:pond_mothers_count="{ row }">
      <span>{{ ftm__digitPrecision(row.data.pond_mothers_count, 1) }}</span>
    </template>
    <template v-slot:shrimp_talk_working_hours="{ row }">
      <span>{{ ftm__digitPrecision(row.data.shrimp_talk_working_hours, 1) >=0 ? ftm__digitPrecision(row.data.shrimp_talk_working_hours, 1) : '--' }}</span>
    </template>
    <template v-slot:feeder_type="{ row }">
      <span>{{ row.data.feeder_type ? row.data.feeder_type : '--' }}</span>
    </template>
    <template v-slot:feeding_level="{ row }">
      <span>{{ row.data.feeding_level ? row.data.feeding_level : '--' }}</span>
    </template>
    <template v-slot:pond_response_quality="{ row }">
      <span>{{ ftm__digitPrecision(row.data.pond_response_quality, 1) >=0 ? ftm__digitPrecision(row.data.pond_response_quality, 1) : '--' }}</span>
    </template>
    <template v-slot:biomass_per_pond_mother_header="{ header }">
      <er-text-tag
        :text-to-scroll="`${header.label} ${header.sub_label}`"
        :threshold-characters="16"
      >
        <span>{{ header.label }}</span>
        <span class="header-sub-label">({{ header.sub_label }})</span>
      </er-text-tag>
    </template>
    <template v-slot:biomass_per_pond_mother="{ row }">
      <p
        v-upm__units.digitPrecision="getBiomassAndUnits(row.data.biomass_per_pond_mother)"
      ></p>
      <!-- <span>{{ ftm__formatNumber(ftm__digitPrecision(row.data.biomass_per_pond_mother, 1), userCountryCode) ?
        accountFormattedFloatValue(ftm__digitPrecision(row.data.biomass_per_pond_mother, 1), userCountryCode) : '--'}}</span> -->
    </template>
    <template v-slot:biomass_per_ha_header="{ header }">
      <er-text-tag
        :text-to-scroll="`${header.label} ${header.sub_label}`"
        :threshold-characters="16"
      >
        <span>{{ header.label }}</span>
        <span class="header-sub-label">({{ header.sub_label }})</span>
      </er-text-tag>
    </template>
    <template v-slot:biomass_per_ha="{ row }">
      <p
        v-upm__units.digitPrecision="getBiomassAndUnits(row.data.biomass_per_ha)"
      ></p>
      <!-- <span>{{ ftm__digitPrecision(row.data.biomass_per_ha, 1) ? accountFormattedFloatValue(ftm__digitPrecision(row.data.biomass_per_ha, 1), userCountryCode) : '--' }}</span> -->
    </template>
    <template v-slot:shrimps_per_pond_mother="{ row }">
      <span>{{ ftm__digitPrecision(row.data.shrimps_per_pond_mother, 1) >=0 ? accountFormattedFloatValue(ftm__digitPrecision(row.data.shrimps_per_pond_mother, 1), userCountryCode) : '--' }}</span>
    </template>
    <template v-slot:stocking_density="{ row }">
      <span>{{ ftm__digitPrecision(row.data.stocking_density, 1) >=0 ? accountFormattedFloatValue(ftm__digitPrecision(row.data.stocking_density, 1), userCountryCode) : '--' }}</span>
    </template>
    <template v-slot:total_dispensed_feed_for_manual_header="{ header }">
      <er-text-tag
        :text-to-scroll="`${header.label} ${header.sub_label}`"
        :threshold-characters="16"
      >
        <span>{{ header.label }}</span>
        <span class="header-sub-label">({{ header.sub_label }})</span>
      </er-text-tag>
    </template>
    <template v-slot:total_dispensed_feed_for_manual="{ row }">
      <span>{{ ftm__digitPrecision(row.data.total_dispensed_feed_for_manual, 1) >= 0 ?
        accountFormattedFloatValue(ftm__digitPrecision(row.data.total_dispensed_feed_for_manual, 1), userCountryCode) : '--'
        }}</span>
    </template>
    <template v-slot:total_dispensed_feed_in_auto_mode_header="{ header }">
      <er-text-tag
        :text-to-scroll="`${header.label} ${header.sub_label}`"
        :threshold-characters="16"
      >
        <span>{{ header.label }}</span>
        <span class="header-sub-label">({{ header.sub_label }})</span>
      </er-text-tag>
    </template>
    <template v-slot:total_dispensed_feed_in_auto_mode="{ row }">
      <span>{{ ftm__digitPrecision(row.data.total_dispensed_feed_in_auto_mode, 1) >= 0 ?
        accountFormattedFloatValue(ftm__digitPrecision(row.data.total_dispensed_feed_in_auto_mode, 1), userCountryCode) : '--'
        }}</span>
    </template>
    <template v-slot:total_dispensed_feed_in_schedule_mode_header="{ header }">
      <er-text-tag
        :text-to-scroll="`${header.label} ${header.sub_label}`"
        :threshold-characters="16"
      >
        <span>{{ header.label }}</span>
        <span class="header-sub-label">({{ header.sub_label }})</span>
      </er-text-tag>
    </template>
    <template v-slot:total_dispensed_feed_in_schedule_mode="{ row }">
      <span>{{ ftm__digitPrecision(row.data.total_dispensed_feed_in_schedule_mode, 1) >= 0 ?
              accountFormattedFloatValue(ftm__digitPrecision(row.data.total_dispensed_feed_in_schedule_mode, 1), userCountryCode) : '--'
        }}</span>
    </template>
    <template v-slot:total_dispensed_feed_in_basic_mode_header="{ header }">
      <er-text-tag
        :text-to-scroll="`${header.label} ${header.sub_label}`"
        :threshold-characters="16"
      >
        <span>{{ header.label }}</span>
        <span class="header-sub-label">({{ header.sub_label }})</span>
      </er-text-tag>
    </template>
    <template v-slot:total_dispensed_feed_in_basic_mode="{ row }">
      <span>{{ ftm__digitPrecision(row.data.total_dispensed_feed_in_basic_mode, 1) >= 0 ?
            accountFormattedFloatValue(ftm__digitPrecision(row.data.total_dispensed_feed_in_basic_mode, 1), userCountryCode) : '--'
        }}</span>
    </template>
    <template v-slot:shrimp_talks_count="{ row }">
      <span>{{ ftm__digitPrecision(row.data.shrimp_talks_count, 1) }}</span>
    </template>
  </er-data-tables>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin.js";
import redirectsMixin from "@/mixins/redirectsMixin.js";
import filtersMixin from "@/mixins/filtersMixin.js";
import datesHandlerMixin from "@/mixins/datesHandlerMixin.js";
import { mapActions, mapGetters } from "vuex";
import Pond from "@/model/pond";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
export default {
  name: "AllPondsTable",
  mixins: [
    errorHandlerMixin,
    redirectsMixin,
    filtersMixin,
    datesHandlerMixin,
    userPreferenceMixin
  ],
  data: function () {
    return {
      // pondHealthData: [],
      numbers: [],
      params: {
        get_all: true,
        status: ["ACTIVE"]
      },
      activeName: "first",
      testData: [
        { do: 12, color: "orange" },
        { do: 34, color: "rgb(57, 204, 100)" },
        { do: 1, color: "red" }
      ],
      lastDoDateLoading: true,
      doDateRangeLoading: true,
      abwShowintialCol: 1,
      abwShowColOffset: 3,
      awgShowintialCol: 1,
      awgShowColOffset: 3,
      tableLoading: false
    };
  },
  props: {
    filteredPonds: Array,
    loading: Boolean,
    selectedHeaders: Array,
    columns: Object,
    pondSearch: String,
    userCountryCode: { type: String, default: "IN" }
  },

  watch: {
    selectedHeaders: function (newVal, oldVal) {
      this.$nextTick(() => {
        if (this.$refs.pondInfotable) {
          this.$refs.pondInfotable.$refs.dataTables.$refs.elTable.doLayout();
        }
      });
    },
    pondSearch: function (newVal, oldVal) {
      return newVal;
    },
    abwShowintialCol: function (newVal, oldVal) {
      this.$nextTick(() => {
        if (this.$refs.pondInfotable) {
          this.$refs.pondInfotable.$refs.dataTables.$refs.elTable.doLayout();
        }
      });
    },
    awgShowintialCol: function (newVal, oldVal) {
      this.$nextTick(() => {
        if (this.$refs.pondInfotable) {
          this.$refs.pondInfotable.$refs.dataTables.$refs.elTable.doLayout();
        }
      });
    }
  },
  computed: {
    ...mapGetters("pondInfo", {
      selectedPondIds: "selectedPondIds",
      selectedPonds: "selectedPonds",
      getPondIdToLatestNABWValues: "getPondIdToLatestNABWValues",
      getPondIdToLatestNDOTempValues: "getPondIdToLatestNDOTempValues",
      getSummaryOfLatestDOTemp: "getSummaryOfLatestDOTemp",
      getSummaryOfLatestNABWValues: "getSummaryOfLatestNABWValues",
      getDoRangeStartDate: "getDoRangeStartDate",
      getDoInDateRange: "getDoInDateRange",
      getPondMode: "getPondMode"
    }),
    ...mapGetters("user", {
      getUserProfile: "getUserProfile",
      getUserCountryName: "getUserCountryName",
      getPreferredUnits: "getPreferredUnits"
    }),
    tablePonds() {
      return this.$lodash.cloneDeep(this.filteredPonds).map((x) => {
        x.survival_percentage =
          x.status === "INACTIVE" ? 0 : x.survival_percentage || 100;
        return x;
      });
    },
    getTotalFeed() {
      return (totalFeedValue) => {
        const totalFeedAndUnits = {
          value: totalFeedValue || 0,
          previousunits: this.upm__backendUnits.feed_units,
          units: this.upm__feedUnits,
          modifiersParams: {
            digitPrecision: [1],
            formatNumber: [this.userCountryCode]
          },
          displayFormatter: () => {
            return ``;
          }
        };
        return totalFeedAndUnits;
      };
    },

    getDispensedFeed() {
      return (dispensedValue) => {
        const totalDispensedFeedAndUnits = {
          value: this.accountFormattedFloatValue(dispensedValue || 0, this.userCountryCode),
          previousunits: this.upm__backendUnits.feed_units,
          units: this.upm__feedUnits,
          modifiersParams: {
            digitPrecision: [1],
            formatNumber: [this.userCountryCode]
          },
          displayFormatter: () => {
            return ``;
          }
        };
        return totalDispensedFeedAndUnits;
      };
    },

    getBiomassAndUnits() {
      return (biomassValue) => {
        const biomassAndUnits = {
          value: biomassValue || 0,
          previousunits: this.upm__backendUnits.biomass_units,
          units: this.getPreferredUnits.biomass_units,
          modifiersParams: {
            digitPrecision: [1],
            formatNumber: [this.userCountryCode]
          },
          displayFormatter: () => {
            return ``;
          }
        };
        return biomassAndUnits;
      };
    },
    getPondSize() {
      return (size, units) => {
        const pondSizeAndUnits = {
          value: size || 0,
          previousunits: units,
          units: this.upm__getPondSizeUnits,
          modifiersParams: { digitPrecision: [2] },
          displayFormatter: () => {
            return ``;
          }
        };
        return pondSizeAndUnits;
      };
    },
    getFeedPerAreaConfig() {
      return (arrValues, arrUnits) => {
        const feedPerAreaConfig = {
          value: arrValues,
          previousunits: arrUnits,
          units: ["kg", this.upm__getPondSizeUnits],
          modifiersParams: { digitPrecision: [1] },
          operation: ([feed, area]) => {
            return feed / area;
          },
          displayFormatter: () => {
            return ``;
          }
        };
        return feedPerAreaConfig;
      };
    },
    getRowNameFirst() {
      const row = this.data[Object.keys(this.data)[0]];
      return Object.keys(row[Object.keys(row)[0]])[0];
    },
    getRowNameSecond() {
      const row = this.data[Object.keys(this.data)[0]];
      return Object.keys(row[Object.keys(row)[0]])[1];
    },
    updatePondSizeBasedOnUnits() {
      return (size, units) => {
        return this.ftm__digitPrecision(
          Number(
            this.upm__convertUnits(
              size,
              this.upm__backendUnits.pond_area_units,
              this.getPreferredUnits.pond_area_units
            )
          ),
          2
        );
      };
    },
    updateBiomassBasedOnUnits() {
      return (biomass) => {
        return this.ftm__digitPrecision(
          Number(
            this.upm__convertUnits(
              biomass,
              this.upm__backendUnits.biomass_units,
              this.getPreferredUnits.biomass_units
            )
          ),
          1
        );
      };
    },
    updateFeedBasedOnUnits() {
      return (feed) => {
        if (isNaN(feed)) {
          return 0;
        }
        return this.ftm__digitPrecision(
          Number(
            this.upm__convertUnits(
              feed,
              this.upm__backendUnits.feed_units,
              this.upm__feedUnits
            )
          ),
          1
        );
      };
    },
    tableProps: function () {
      return {
        size: "mini",
        showSummary: true,
        rowClassName: this.rowClassName,
        summaryMethod: this.getSummaries,
        defaultSort: {
          prop: "title",
          order: "ascending"
        },
        hasNestedColumn: true,
        height: "calc(100vh - var(--height-gutter))"
      };
    },
    mode: function () {
      return (data) => {
        const value = this.getPondMode[data._id];
        if (value === "SHRIMP_TALK") {
          return this.$t("PM_automatic");
        } else if (value === "HYBRID") {
          return this.$t("hybrid");
        } else if (value === "FARMER") {
          return this.$t("Comn_schedule");
        } else if (value === "MANUAL") {
          return this.$t("PM_sch_mode4");
        } else if (value === "NO_PMS") {
          return "-";
        }
      };
    },
    getProgressBarColor() {
      return (value) => {
        return [
          { l: 0, h: 25, color: "#EB4841" },
          { l: 26, h: 50, color: "#F58949" },
          { l: 51, h: 75, color: "#FFC949" },
          { l: 76, h: 100, color: "rgb(57, 204, 100)" }
        ].reduce((acc, curr) => {
          const mValue = Math.ceil(value);
          if (curr.l <= mValue && curr.h >= mValue) {
            acc = curr.color;
          }
          return acc;
        }, "#000000");
      };
    },
    getTitleForFeed() {
      return (pondSummary) => {
        return `${this.$t("Comn_manual_feed")}: ${
          pondSummary.manual_feed
        }\n${this.$t("Comn_pond_mother")}: ${
          pondSummary.schedule_dispensed_feed
        }`;
      };
    },
    feedDispensed() {
      return (row) => {
        const feedDispensed = this.toFixedAndParseFloat(
          row.feed_dispensed || 0
        );
        const feed = this.toFixedAndParseFloat(row.total_feed || 0);
        console.log(feedDispensed);
        console.log(feed);
        return `${Math.round(feedDispensed)}/${Math.round(feed)}`;
      };
    },
    generateColorForAWG() {
      return (data) => {
        const { awg } = data;
        if (awg < 0) {
          return { color: "Red" };
        } else if (awg > 0) {
          return { color: "rgb(57, 204, 100)" };
        } else {
          return { color: "Grey" };
        }
      };
    },
    generateColorForBiomass() {
      return (data) => {
        const { biomass, last_biomass } = data;
        const diff = (biomass - last_biomass) / 1000;
        if (diff < 0) {
          return { color: "Red" };
        } else if (diff >= 1) {
          return { color: "rgb(57, 204, 100)" };
        } else {
          return { color: "Grey" };
        }
      };
    },
    generateColorForNetFeed() {
      return (data) => {
        const { dispensed_feed } = data;
        if (dispensed_feed < 0) {
          return { color: "Red" };
        } else if (dispensed_feed > 0) {
          return { color: "rgb(57, 204, 100)" };
        } else {
          return { color: "Grey" };
        }
      };
    },
    generateColorForMode() {
      return (data) => {
        return Pond.generateColorForMode(this.getPondMode[data._id]);
      };
    },
    generateColorForDo() {
      return (data) => {
        if (data.do < data.doConfig.critical_lower_limit) {
          return { color: "red" };
        } else if (
          data.do >= data.doConfig.critical_lower_limit &&
          data.do < data.doConfig.lower_limit
        ) {
          return { color: "orange" };
        } else if (data.do >= data.doConfig.lower_limit) {
          return { color: "rgb(57, 204, 100)" };
        } else {
          return { color: "black" };
        }
      };
    },
    generateColorForTemp() {
      return (data) => {
        // const { do } = data;
        const { tempConfig, temp } = data;
        const { lower_limit, upper_limit, tolerance } = tempConfig;

        if (temp >= lower_limit && temp <= upper_limit) {
          return { color: "rgb(57, 204, 100)" };
        } else if (
          temp >= lower_limit - tolerance &&
          temp <= upper_limit + tolerance
        ) {
          return { color: "orange" };
        } else if (
          temp < lower_limit - tolerance ||
          temp > upper_limit + tolerance
        ) {
          return { color: "red" };
        } else {
          return { color: "black" };
        }
      };
    },
    getDoDateSince() {
      if (!this.getDoRangeStartDate) {
        return "--h --m";
      }
      const now = this.dhm__dateUtilsLib.getCurrDateInGivenTZ(
        this.getUserTimeZoneString
      );
      const expiration = this.dhm__dateUtilsLib.utcToZonedTime(
        this.dhm__dateUtilsLib.formatUserUTCISOToUTCISO(
          this.getDoRangeStartDate,
          this.getUserTimeZoneString
        ),
        this.getUserTimeZoneString
      );
      const diffDuration = this.dhm__dateUtilsLib.intervalToDuration({
        start: expiration,
        end: now
      });
      return this.dhm__dateUtilsLib.formatDuration(diffDuration, {
        format: ["years", "months", "days", "hours", "minutes"],
        locale: this.dhm__dateUtilsLib.localesMap[this.$i18n.locale]
      });
      // if (diffDuration.months > 0) {
      //   return `${diffDuration.months}m ${diffDuration.days}d ${diffDuration.hours}h ${diffDuration.minutes}m`;
      // } else if (diffDuration.days > 0) {
      //   return `${diffDuration.days}d ${diffDuration.hours}h ${diffDuration.minutes}m`;
      // } else if (diffDuration.hours > 0) {
      //   return `${diffDuration.hours}h ${diffDuration.minutes}m`;
      // } else {
      //   return `${diffDuration.minutes}m`;
      // }
    }
  },
  mounted() {
    this.updateTableLayout();
  },
  methods: {
    ...mapActions("pondInfo", {
      changeSelectedPonds: "changeSelectedPonds",
      fetchDoRangeStartDate: "fetchDoRangeStartDate",
      fetchDoInDateRange: "fetchDoInDateRange"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    isKeyExists(item, value) {
      return this.$commonUtils.isKeyExists(item, value);
    },
    getFormattedDate(date) {
      const userTime = this.dhm__dateUtilsLib.utcToZonedTime(
        this.dhm__dateUtilsLib.formatUserUTCISOToUTCISO(
          date,
          this.getUserTimeZoneString
        ),
        this.getUserTimeZoneString
      );
      return this.dhm__formatTZ(
        userTime,
        this.$commonUtils.DATE_OBJ_DATE_FORMAT_WITH_YEAR
      );
    },
    onHover: async function (row) {
      const { do: dissolved_oxygen, pgID, pgCode, doConfig: config } = row[0];
      this.lastDoDateLoading = true;
      this.doDateRangeLoading = true;
      let params = {};
      if (dissolved_oxygen < config.critical_lower_limit) {
        params = {
          pond_guard_id: pgID,
          "range[min]": 0,
          "range[max]": config.critical_lower_limit,
          parameter: "dissolved_oxygen"
        };
      } else if (
        dissolved_oxygen >= config.critical_lower_limit &&
        dissolved_oxygen < config.lower_limit
      ) {
        params = {
          pond_guard_id: pgID,
          "range[min]": config.critical_lower_limit,
          "range[max]": config.lower_limit,
          parameter: "dissolved_oxygen"
        };
      } else {
        params = {
          pond_guard_id: pgID,
          "range[min]": config.lower_limit,
          parameter: "dissolved_oxygen"
        };
      }
      const doData = {
        pgCode,
        config
      };
      try {
        await this.fetchDoRangeStartDate(params);
        // this.mixPanelEventGenerator({ eventName: "Ponds Info - DO - Mouse Hover Event" });
      } catch (err) {
        this.ehm__errorMessages(err, false);
        this.lastDoDateLoading = false;
      } finally {
        this.lastDoDateLoading = false;
      }

      try {
        await this.fetchDoInDateRange(doData);
      } catch (err) {
        this.ehm__errorMessages(err, false);
        this.doDateRangeLoading = false;
      } finally {
        this.doDateRangeLoading = false;
      }
    },
    onTempHover: async function (row) {
      const { temp, pgID, tempConfig: config, pgCode } = row[0];
      const { lower_limit, upper_limit, tolerance } = config;
      const criticalLowerLimit = lower_limit - tolerance;
      const criticalUpperLimit = upper_limit + tolerance;
      this.lastDoDateLoading = true;
      this.doDateRangeLoading = true;

      let params = {};

      if (temp > lower_limit && temp <= upper_limit) {
        params = {
          pond_guard_id: pgID,
          "range[min]": lower_limit,
          "range[max]": upper_limit,
          parameter: "temperature"
        };
      } else if (temp > criticalLowerLimit && temp <= lower_limit) {
        params = {
          pond_guard_id: pgID,
          "range[min]": criticalLowerLimit,
          "range[max]": lower_limit,
          parameter: "temperature"
        };
      } else if (temp > upper_limit && temp <= criticalUpperLimit) {
        params = {
          pond_guard_id: pgID,
          "range[min]": upper_limit,
          "range[max]": criticalUpperLimit,
          parameter: "temperature"
        };
      } else if (temp < criticalLowerLimit) {
        params = {
          pond_guard_id: pgID,
          // "range[min]": lower_limit - tolerance,
          "range[max]": criticalLowerLimit,
          parameter: "temperature"
        };
      } else {
        params = {
          pond_guard_id: pgID,
          "range[min]": criticalUpperLimit,
          // "range[max]": upper_limit + tolerance,
          parameter: "temperature"
        };
      }
      const tempData = {
        pgCode,
        config
      };
      // this.mixPanelEventGenerator({ eventName: "Ponds Info - Temp - Mouse Hover Event" });
      try {
        await this.fetchDoRangeStartDate(params);
      } catch (err) {
        this.ehm__errorMessages(err, false);
        this.lastDoDateLoading = false;
      } finally {
        this.lastDoDateLoading = false;
      }
      try {
        await this.fetchDoInDateRange(tempData);
      } catch (err) {
        this.ehm__errorMessages(err, false);
        this.doDateRangeLoading = false;
      } finally {
        this.doDateRangeLoading = false;
      }
    },
    handleRowClick: async function (row, column, event) {
      this.tableLoading = true;
      if (this.selectedPondIds.indexOf(row._id) === -1) {
        if (this.selectedPondIds.length >= 5) {
          const error = {
            type: "FAIL_TO_SAVE",
            errors: [
              {
                message: this.$t(
                  "please-deselect-one-pond-and-select-another-pond"
                )
              }
            ]
          };
          this.ehm__errorMessages(error, false);
          return;
        }
        const ponds = this.$lodash.cloneDeep(this.selectedPonds);
        ponds.push(row);
        await this.changeSelectedPonds({ selected_ponds: ponds });
      } else {
        const index = this.selectedPondIds.indexOf(row._id);
        const ponds = this.$lodash.cloneDeep(this.selectedPonds);
        ponds.splice(index, 1);
        await this.changeSelectedPonds({
          selected_ponds: ponds,
          deleted_pond: row
        });
      }
      this.tableLoading = false;
    },
    formatedNumericValue(value, countryCode) {
      return parseInt(value).toLocaleString(`en-${countryCode}`);
    },
    accountFormattedFloatValue(value, countryCode) {
      return parseFloat(value).toLocaleString(`en-${countryCode}`);
    },
    getBarStyling(index, length, color) {
      if (index === 0) {
        return `width:${200 / length}px; background: ${color}; 
         border-radius: 5px 0px 0px 5px`;
      } else if (index === length - 1) {
        return `width:${200 / length}px; background: ${color}; 
         border-radius: 0px 5px 5px 0px`;
      } else {
        return `width:${200 / length}px; background: ${color}`;
      }
    },
    rowClassName({ row, rowIndex }) {
      if (row && (this.selectedPondIds || []).indexOf(row._id) > -1) {
        return "selected-row";
      } else {
        return "";
      }
    },
    getWeightedAvg(list, multiplyer, base) {
      const weights = list.map((item) => !isNaN(Number(item[multiplyer])) ? Number(item[multiplyer]) : 0);
      const baseList = list.map((item) => !isNaN(Number(item[base])) ? Number(item[base]) : 0);
      if (
        !weights.every((value) => isNaN(value)) ||
        !baseList.every((value) => isNaN(value))
      ) {
        const total = list
          .map((item) => (!isNaN(Number(item[multiplyer])) ? Number(item[multiplyer]) : 0) * (!isNaN(Number(item[base])) ? Number(item[base]) : 0))
          .reduce((a, b) => a + b, 0);
        const divisor = weights.reduce((a, b) => a + b, 0);

        return base === "abw"
          ? this.ftm__digitPrecision(total / divisor, 2)
          : Math.round(total / divisor);
      } else {
        return "--";
      }
    },
    getFCRSummary(list) {
      const netFeed = list.map((item) => Number(item.total_dispensed_feed));
      const biomass = list.map((item) => Number(item.biomass));

      const [totalNetFeed, totalBiomass] = [netFeed, biomass].map((x) =>
        x.reduce((acc, curr) => {
          if (isNaN(curr)) return acc;
          acc += curr;
          return acc;
        }, 0)
      );
      if (totalNetFeed > 0 && totalBiomass > 0) {
        return this.accountFormattedFloatValue(this.ftm__digitPrecision(
          netFeed.reduce((a, b) => a + b, 0) /
            (biomass.reduce((a, b) => a + b, 0) || 1),
          2
        ), this.userCountryCode);
      } else {
        return "--";
      }
    },
    getBiomassSummary(list, field) {
      const totalbio = list
        .map((x) => x[field])
        .reduce((acc, curr) => {
          return acc + curr;
        }, 0);
      return this.updateBiomassBasedOnUnits(totalbio);
    },
    getFeeds(list, attribute) {
      const values = list.map((item) => Math.round(Number(item[attribute])));
      if (!values.every((value) => isNaN(value))) {
        const sum = values.reduce((prev, curr) => {
          const value = Number(curr);
          if (!isNaN(value)) {
            return prev + curr;
          } else {
            return prev;
          }
        }, 0);

        return this.updateFeedBasedOnUnits(sum); //   this.formatedNumericValue(sum, this.userCountryCode);
      } else {
        return "--";
      }
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      const defaultSummaryCalculator = (
        column,
        index,
        formatterFunc,
        roundOffFunc = Math.round
      ) => {
        const sum = data.reduce((acc, item) => {
          const value = Number(item[column.property]);
          if (isNaN(value)) return acc;
          acc += roundOffFunc(value);
          return acc;
        }, 0);
        if (sum > 0) {
          sums[index] =
            column.property === "size"
              ? this.ftm__digitPrecision(
                  this.updatePondSizeBasedOnUnits(+sum),
                  1
                )
              : this.formatedNumericValue(sum, this.userCountryCode);
        } else {
          sums[index] = "--";
        }
      };

      columns.forEach((column, index) => {
        switch (column.property) {
          case "title":
            sums[index] = this.$t("Comn_total");
            break;
          case "biomass":
            // defaultSummaryCalculator(column, index, this.formatedNumericValue);
            sums[index] = this.getBiomassSummary(data, 'biomass');
            break;
          case "post_larva_stocked":
            defaultSummaryCalculator(column, index, this.formatedNumericValue);
            break;
          case "fcr":
            sums[index] = this.getFCRSummary(data);
            break;
          case "survival_percentage":
            sums[index] = `${this.getWeightedAvg(
              data,
              "post_larva_stocked",
              "survival_percentage"
            )}%`;
            break;
          case "total_feed":
            sums[index] = `${this.accountFormattedFloatValue(this.getFeeds(
              data,
              "total_dispensed_feed"
            ), this.userCountryCode)}`; //  (${this.getFeeds(data, "dispensed_feed")})
            break;
          case "feed":
            sums[index] = `${this.accountFormattedFloatValue(this.getFeeds(
              data,
              "feed"
            ), this.userCountryCode)}`;
            break;
          case "dispensed_feed":
            sums[index] = `${this.accountFormattedFloatValue(this.getFeeds(
              data,
              "dispensed_feed"
            ), this.userCountryCode)}`; //  (${this.getFeeds(data, "dispensed_feed")})
            break;
          case "total_dispensed_feed_for_manual":
            sums[index] = `${this.accountFormattedFloatValue(this.getFeeds(
              data,
              "total_dispensed_feed_for_manual"
            ), this.userCountryCode)}`;
            break;
          case "total_dispensed_feed_in_auto_mode":
            sums[index] = `${this.accountFormattedFloatValue(this.getFeeds(
              data,
              "total_dispensed_feed_in_auto_mode"
            ), this.userCountryCode)}`;
            break;
          case "total_dispensed_feed_in_schedule_mode":
            sums[index] = `${this.accountFormattedFloatValue(this.getFeeds(
              data,
              "total_dispensed_feed_in_schedule_mode"
            ), this.userCountryCode)}`;
            break;
          case "total_dispensed_feed_in_basic_mode":
            sums[index] = `${this.accountFormattedFloatValue(this.getFeeds(
              data,
              "total_dispensed_feed_in_basic_mode"
            ), this.userCountryCode)}`;
            break;
          case "biomass_per_pond_mother":
            sums[index] = '--';
            break;
          case "biomass_per_ha":
             sums[index] = '--';
            break;
          case "shrimps_per_pond_mother":
            sums[index] = '--';
            break;
          case "stocking_density":
            sums[index] = '--';
            break;
          case "pond_mothers_count":
            sums[index] = `${this.accountFormattedFloatValue(this.getFeeds(
              data,
              "pond_mothers_count"
            ), this.userCountryCode)}`;
            break;
          case "shrimp_talks_count":
            sums[index] = `${this.accountFormattedFloatValue(this.getFeeds(
              data,
              "shrimp_talks_count"
            ), this.userCountryCode)}`;
            break;
          case "pond_response_quality":
            sums[index] = '--';
            break;
          case "shrimp_talk_working_hours":
            sums[index] = '--';
            break;
          case "size":
            defaultSummaryCalculator(
              column,
              index,
              (val) => this.ftm__digitPrecision(val, 1),
              (value) => value
            );
            break;
          default:
            sums[index] = "--";
        }
      });

      return sums;
    },
    sortMethods() {
      return {
        title: this.sortPondWise
      };
    },
    sortPondWise(a, b) {
      return this.$commonUtils.alphaNumericComparator(a, b);
    },
    abwnested(event) {
      this.mixPanelEventGenerator({ eventName: "Ponds Info Table - ABW+ Column" });
      event.stopPropagation();
      this.abwShowintialCol = this.abwShowintialCol === 1 ? 4 : 1;
      this.abwShowColOffset = this.abwShowintialCol === 1 ? 3 : 0;
      event.target.classList.toggle("el-icon-minus");
      this.updateTableLayout();
      return this.abwShowintialCol;
    },
    awgnested(event) {
      this.mixPanelEventGenerator({ eventName: "Ponds Info Table - AWG+ Column" });
      event.stopPropagation();
      this.awgShowintialCol = this.awgShowintialCol === 1 ? 4 : 1;
      this.awgShowColOffset = this.awgShowintialCol === 1 ? 3 : 0;
      event.target.classList.toggle("el-icon-minus");
      this.updateTableLayout();
      return this.awgShowintialCol;
    },
    updateTableLayout() {
      this.$nextTick(() => {
        if (this.$refs.pondInfotable) {
          this.$refs.pondInfotable.$refs.dataTables.$refs.elTable.doLayout();
        }
      });
    },
    // onTotalFeedHover() {
    //   console.log("0000");
    //   this.mixPanelEventGenerator({ eventName: "Ponds Info - NetFeed - Mouse Hover Event" });
    // }
  }
};
</script>

<style lang="scss">
@import "@/styles/mixins.scss";

.do-pop-container {
  .el-icon-loading {
    margin-top: 8px;
  }
  .do-last-date {
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );
    color: #979797;
    margin-bottom: 12px;
    span {
      color: black;
    }
  }
  .do-hours-label {
    color: #979797;
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );
  }
  .row__bar-container {
    .bar {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      color: black;
      &__filler {
        height: 6px;
        margin-right: 0px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        // background: linear-gradient(225deg, #2d9cdb 0%, #155dbe 100%);
        // &:hover {
        //   background: linear-gradient(45deg, #2d9cdb 0%, #155dbe 100%);
        // }
      }
    }
  }
}
.card-all-ponds-table {
  @include responsiveProperty(--height-gutter, 122px, 131px, 140px);
  margin-top: 10px;
  .el-progress {
    width: 100%;
  }
  .er-data-tables__header-row:nth-child(2) {
    display: none;
  }
  .el-col.el-col-24.abw-4 {
    color: #155dbe;
  }
  .selected-row {
    background: aliceblue;
  }
}

.total-feed-pop-container.el-popover {
  color: #979797;
  @include responsiveProperty(
    font-size,
    $app_font_size_small,
    $app_font_size_1,
    $app_font_size_2
  );
  padding: 4px;
}
</style>
